import { Link } from "../Link";
import PropTypes from "prop-types";
import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import { ScreenWidthContext, FontLoadedContext } from "../../layouts";
import config from "../../../content/meta/config";
import Menu from "../Menu";

import avatar from "../../images/jpg/avatar.jpg";

import { usePageContext } from "../../i18n";
import { useTranslation } from 'react-i18next'

const Header = ({ pages, path, theme, }) => {
  const { t } = useTranslation();
  const [fixed, setFixed] = useState(false);
  const { lang } = usePageContext();
  const filteredPages = pages.filter(p => p.node.fields.langKey === lang);

  const visibilitySensorChange = val => {
    if (val) {
      setFixed(false);
    } else {
      setFixed(true);
    }
  };

  const getHeaderSize = () => {
    const fixedText = fixed ? "fixed" : "";

    const homePages = [`/${lang}`]; //, `/${lang}/newsletter-pl`];

    const homepage = homePages.some(page => path === `${page}/` || path === `${page}`) ? "homepage" : "";

    return `${fixedText} ${homepage}`;
  };

  return (
    <React.Fragment>
      <header className={`header ${getHeaderSize()}`}>
        <Link to="/" className="logoType">
          <div className="logo">
            <img src={avatar} alt={config.siteTitle} />
          </div>
          <div className="type">
            <h1>{t("header.title") || config.headerTitle}</h1>
            <h2>{t("header.subTitle") || config.headerSubTitle}</h2>
          </div>
        </Link>
        <FontLoadedContext.Consumer>
          {loaded => (
            <ScreenWidthContext.Consumer>
              {width => (
                <Menu
                  path={path}
                  fixed={fixed}
                  screenWidth={width}
                  fontLoaded={loaded}
                  pages={filteredPages}
                  theme={theme}
                />
              )}
            </ScreenWidthContext.Consumer>
          )}
        </FontLoadedContext.Consumer>
      </header>
      <VisibilitySensor onChange={visibilitySensorChange}>
        <div className="sensor" />
      </VisibilitySensor>

      {/* --- STYLES --- */}
      <style jsx>{`
          .header {
            align-items: center;
            justify-content: center;
            background-color: ${theme.color.neutral.white};
            display: flex;
            height: ${theme.header.height.default};
            position: relative;
            top: 0;
            width: 100%;
            align-items: center;

            :global(a.logoType) {
              align-items: center;
              display: flex;
              flex-direction: "column";
              color: ${theme.text.color.primary};

              .logo {
                flex-shrink: 0;
              }
            }

            &.homepage {
              position: absolute;
              background-color: transparent;
              height: ${theme.header.height.homepage};
            }
          }

          h1 {
            font-size: ${theme.font.size.m};
            font-weight: ${theme.font.weight.standard};
            margin: ${theme.space.stack.xs};
          }

          h2 {
            font-weight: ${theme.font.weight.standard};
            font-size: ${theme.font.size.xxs};
            letter-spacing: 0;
            margin: 0;
          }

          .logo {
            border-radius: 65% 75%;
            border: 1px solid #eee;
            display: inline-block;
            height: 44px;
            margin: ${theme.space.inline.default};
            overflow: hidden;
            width: 44px;
            transition: all 0.5s;

            .homepage & {
              height: 60px;
              width: 60px;
            }

            img {
              width: 100%;
            }
          }

          .sensor {
            display: block;
            position: absolute;
            bottom: 0;
            z-index: 1;
            left: 0;
            right: 0;
            height: 1px;
            top: ${path === `/${lang}/` ? theme.header.height.homepage : theme.header.height.default};
          }

          @from-width tablet {
            .header {
              padding: ${theme.space.inset.l};

              &.homepage {
                height: ${theme.header.height.homepage};
              }
            }
          }

          @below desktop {
            .header.homepage {
              .logo {
                border: none;
              }

              :global(a.logoType),
              h1 {
                color: ${theme.color.neutral.white};
              }
              h2 {
                color: ${theme.color.neutral.gray.d};
              }
            }
          }

          @from-width desktop {
            .header {
              align-items: center;
              background-color: ${theme.color.neutral.white};
              display: flex;
              position: absolute;
              top: 0;
              width: 100%;
              justify-content: space-between;
              transition: padding 0.5s;

              &.fixed {
                height: ${theme.header.height.fixed};
                background-color: ${theme.color.neutral.white};
                left: 0;
                padding: 0 ${theme.space.m};
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 1;

                h1 {
                  margin: ${theme.space.stack.xxs};
                }

                h2 {
                  display: none;
                }
              }

              &.homepage:not(.fixed) {
                :global(a.logoType),
                h1 {
                  color: ${theme.color.neutral.white};
                }
                h2 {
                  color: ${theme.color.neutral.gray.d};
                }
              }
            }

            .header :global(a.logoType) {
              text-align: left;
              flex-direction: row;
              flex-shrink: 0;
              width: auto;
            }

            .logo {
              margin: ${theme.space.inline.default};

              .fixed & {
                height: 36px;
                width: 36px;
              }

              .header.homepage:not(.fixed) & {
                border: none;
              }
            }

            h2 {
              animation-duration: ${theme.time.duration.default};
              animation-name: h2Entry;
            }

            @keyframes h2Entry {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          }
        `}</style>
    </React.Fragment>
  );
}

Header.propTypes = {
  pages: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default Header;
